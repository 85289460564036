.searchbox > div .el-input {
  flex: 1;
}
.searchBox {
  padding: 0;
}
.searchBox .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.searchboxTwo .searchboxItems {
  display: flex;
  align-items: center;
}
.searchboxTwo .searchboxItems .itemLabel {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
.searchboxItems {
  display: flex;
  align-items: center;
}
.searchboxItems .itemLabel {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
.operationControlCurr > div {
  width: 100%;
  justify-content: space-between;
}
.operationControlCurr .itemLabel {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
.courseBox {
  display: flex;
  flex-wrap: wrap;
}
.courseBox .form-item {
  width: 50%;
}
.noPassBtn {
  background-color: #999;
  border: 1px solid #999;
}
.noPassBtn:hover {
  background-color: #999;
  border: 1px solid #999;
}
