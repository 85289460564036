



































































































































































































































































































































































































































































































































































































































































































.searchbox > div .el-input {
  flex: 1;
}
// .el-dialog__body {
//   overflow: auto;
// }
// .projectUser {
//   min-width: 6rem !important;
// }
// .qrcode {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   img {
//     width: 400px;
//     height: 400px;
//     background-color: #999; //设置白色背景色
//   }
// }
// .refreshcode {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   /* padding: 1rem; */
//   padding-top: 1rem;
// }
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
// .docsDialog {
//   .el-form-item {
//     margin-bottom: 0.5rem;
//   }
// }
// .ellipsis {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   height: 23px;
//   width: 60px;
//   display: block;
// }
.searchboxTwo {
  .searchboxItems {
    display: flex;
    align-items: center;
    .itemLabel {
      min-width: 5rem;
      text-align: right;
      font-size: 14px;
      padding: 0 0.5rem;
    }
  }
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.courseBox {
  display: flex;
  flex-wrap: wrap;
  .form-item {
    width: 50%;
  }
}
.noPassBtn {
  background-color: #999;
  border: 1px solid #999;
}
.noPassBtn:hover {
  background-color: #999;
  border: 1px solid #999;
}
